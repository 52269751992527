import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Login from "./views/index";
import Home from "./views/home/home";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import  './style.scss';

ReactDOM.render(
      <BrowserRouter>
          <Switch>
              <Route path="/home" component={Home} />
              <Route path="/" component={Login} />
          </Switch>
      </BrowserRouter>
 ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
