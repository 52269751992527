import React from "react";
import './index.scss';
import './index.responsive.scss';
import {
    withRouter
} from "react-router-dom";
import logo from '../../assets/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChrome, faFirefox, faEdge, faGoogleDrive } from '@fortawesome/free-brands-svg-icons';
import axios from 'axios';
import CONSTANTS from "../../constants";
const QRCode = require('qrcode.react');

interface ConversationPanelProps{
    history:any
}
type Props = ConversationPanelProps

class Login extends React.Component<Props>{
    private apiServer = CONSTANTS.api_server;
    private storesData = {
        chrome: {
            link: 'https://chrome.google.com/webstore/detail/did-siop/ondpkioapbcbamnjdimjfhaelgojblad?hl=en-US&gclid=EAIaIQobChMI8MWH0Pq46gIVig4rCh3qDwHKEAAYASAAEgJiiPD_BwE',
        },
        edge: {
            link: 'https://microsoftedge.microsoft.com/addons/detail/didsiop/obkplhmcoocpddcaompifaciljjnclfk?hl=en-GB',
        },
        fireFox: {
            link: 'https://addons.mozilla.org/en-US/firefox/addon/did-siop/',
        },
        drive: {
            link: 'https://drive.google.com/drive/folders/1vTUBK9G9A5rmZ_-ZojALi_j0oXoIqjgQ?usp=sharing',
        }
    };

    state = {
        request: '',
        session_id: ''
    };

    componentDidMount(): void {
        axios.get(`${this.apiServer}/getRequest`).then((res)=> {
            console.log(res);
            this.setState({
                request: res.data.req,
                session_id: res.data.session_id
            });
            const source = new EventSource(`${this.apiServer}/subscribe?session_id=${res.data.session_id}`);
            source.addEventListener('message', message => {
                const data = JSON.parse(message.data);
                console.log(data);
                if(data.success){
                    source.close();
                    this.props.history.push({
                        pathname:'/home',
                        state: {data: res.data.session_id}
                    })
                }
            });
        })

    }

    render() {
        return (
            <div className={"container-fluid d-flex flex-column align-items-center justify-content-center index-wrapper"}>
                <div className={"row index-wrapper-row"}>
                    <div className={"col-md-4 d-flex flex-column align-items-center justify-content-center login-controls"}>
                        <div className={"container-fluid px-0 d-flex flex-column justify-content-center align-items-center"}>
                            <div className="d-flex justify-content-center logo">
                                <img src={logo} alt="did siop logo"/>
                            </div>
                            <div className="d-flex flex-column align-items-center justify-content-center login-btn">
                                <button className="btn btn-success btn-large" data-did-siop={this.state.request}>Login With Your DID</button>
                            </div>
                            <div className="d-flex justify-content-center qr-code">
                                <QRCode value={this.state.request} size={250}/>
                            </div>
                            <div className="d-flex justify-content-center ">
                                <p className="header">Or scan this QR with did-siop-mobile app to login !</p>
                            </div>
                        </div>
                    </div>


                    <div className={"col-md-8 d-flex flex-column align-items-start info"}>
                        <div className="heading">
                            <h1>Relying Party App Using DID-SIOP (Auth-Code Flow)</h1>
                        </div>
                        <div className="container-fluid px-0 d-flex flex-column important">
                            <h5>Important</h5>
                            <p>This application works and tested on Google Chrome, Mozila Firefox and Microsoft Edge browsers. Please make sure you have installed the Extension DID-SIOP before trying to login. You could download it here.
                            </p>
                            <div className={"container stores"}>
                                <div className="row">
                                    <div className="col-3 d-flex flex-column justify-content-center align-items-center">
                                        <div className="d-flex flex-column store">
                                            <a href={this.storesData.chrome.link} target="_blank"  rel="noreferrer">
                                                <FontAwesomeIcon icon={faChrome} />
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-3 d-flex flex-column justify-content-center align-items-center">
                                        <div className="d-flex flex-column store">
                                            <a href={this.storesData.fireFox.link} target="_blank"  rel="noreferrer">
                                                <FontAwesomeIcon icon={faFirefox} />
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-3 d-flex flex-column justify-content-center align-items-center">
                                        <div className="d-flex flex-column store">
                                            <a href={this.storesData.edge.link} target="_blank"  rel="noreferrer">
                                                <FontAwesomeIcon icon={faEdge} />
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-3 d-flex flex-column justify-content-center align-items-center">
                                        <div className="d-flex flex-column store">
                                            <a href={this.storesData.drive.link} target="_blank"  rel="noreferrer">
                                                <FontAwesomeIcon icon={faGoogleDrive} />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <a className="instructions-video" href="https://www.youtube.com/watch?v=Hd9PwHotU84"
                               target="_blank" rel="noreferrer">Step by step instructions to set up the extension (Video)</a>
                        </div>
                        <div className="container-fluid px-0 d-flex flex-column resources">
                            <h5>Resources</h5>
                            <ul>
                                <li><a href="https://identity.foundation/did-siop/" target="_blank" rel="noreferrer">DID SIOP
                                    Specification</a></li>
                                <li><a href="https://github.com/RadicalLedger/did-siop-chrome-ext" target="_blank">Source
                                    Code for Browser Extension</a></li>
                                <li><a href="https://www.npmjs.com/package/did-siop" target="_blank" rel="noreferrer">did-siop npm library.
                                    Used in relying party apps</a></li>
                                <li><a href="https://cdn.jsdelivr.net/npm/did-siop@1.4.4/dist/browser/did-siop.min.js"
                                       target="_blank">Library via CDN</a></li>
                                <li><a href="https://github.com/RadicalLedger/did-siop-rp-web-test" target="_blank" rel="noreferrer">Sourcecode
                                    for Relying Party sample application (this application)</a></li>
                            </ul>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

// @ts-ignore
export default withRouter(Login);
